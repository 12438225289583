import { graphql } from "gatsby"
import React from "react"
import { Heading } from "../components/01_atoms/Heading/Heading"
import { Paragraph } from "../components/01_atoms/Paragraph/Paragraph"
import Layout from "../components/layout"
import Seo from "../components/seo"

import Section from "../components/03_organisms/Section/Section"
import Spacer from "../components/01_atoms/Spacer/Spacer"

const IndexPage = ({ data, pageContext }) => {
  const pageData = data.nodeLegal
  return (
    <Layout>
      {pageData && (
        <>
          <Seo
            title={pageData.field_meta_title}
            description={pageData.field_meta_description}
          />
          <Section content>
            <Spacer height={50} />
            <Heading size="xxl">{pageData.title}</Heading>
            <Paragraph>
              <span
                dangerouslySetInnerHTML={{
                  __html: pageData.body.processed,
                }}
              />
            </Paragraph>
          </Section>
        </>
      )}
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query ($language: String!, $slug: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    nodeLegal(
      path: { alias: { eq: $slug } }
      langcode: { eq: $language }
      status: { eq: true }
    ) {
      title
      path {
        alias
      }
      body {
        processed
      }
      field_meta_description
      field_meta_title
      internal {
        type
      }
    }
  }
`
